const i18nUtils = require('../i18n/src/utils/index.js');

const defaultLocale = process.env.DEFAULT_LOCALE || 'en';
const locales = (process.env.LOCALES || 'en').replace(/_/g, '-').split(',');

module.exports = {
    defaultLocale: defaultLocale,
    loadLocaleFrom: i18nUtils.loadLocaleFrom,
    locales: locales,
    logger: i18nUtils.missingKeyLogger,
    loggerEnvironment: 'both',
    logBuild: false,
    pages: {
        '*': ['actions', 'common', 'components', 'datetime', 'url'],
        '/ads-txt': ['ads-txt'],
        '/flag': ['comments'],
        '/opta-exports': ['opta-exports'],
        '/poll': ['poll'],
        '/sandbox': ['sandbox'],
        'rgx:/ads': ['ads'],
        'rgx:/ai-prompts': ['ai-prompts'],
        'rgx:/ai-results': ['ai-results'],
        'rgx:/articles': ['articles'],
        'rgx:/artikel': ['articles', 'poll'],
        'rgx:/audit-logs': ['audit-logs'],
        'rgx:/blocked-domain': ['blocked-domain'],
        'rgx:/blocked-ips': ['blocked-ips'],
        'rgx:/bookmaker': ['bookmaker'],
        'rgx:/comments': ['comments', 'ip-qs', 'recaptcha'],
        'rgx:/experiments': ['experiments'],
        'rgx:/filtered-comments': ['filtered-comments'],
        'rgx:/forbidden-words': ['forbidden-words'],
        'rgx:/match-reports': ['match-reports'],
        'rgx:/match-ticker': ['match-ticker'],
        'rgx:/module-schedule': ['module-schedule'],
        'rgx:/nav-items': ['nav-items'],
        'rgx:/news-category': ['news-category'],
        'rgx:/notificationbar': ['notificationbar'],
        'rgx:/odds-dossier': ['oddsdossier', 'module-schedule'],
        'rgx:/pages': ['pages'],
        'rgx:/redirects': ['redirects'],
        'rgx:/tags': ['tags', 'meta'],
        'rgx:/users': ['user'],
        'rgx:/videofeed': ['videofeed'],
    },
};
